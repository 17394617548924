<template>
    <div v-if="activity">
        <b-card title="Activity">
            <b-table-simple hover small caption-top responsive>
                <b-thead>
                    <b-tr>
                        <b-th>Item</b-th>
                        <b-th>Description</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>Title</b-td>
                        <b-td>{{ activity.title }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Status</b-td>
                        <b-td>
                            <b-badge :variant="`light-${activityCategoryStatus(activity.status)}`">
                                {{ activity.status.toUpperCase() }}
                            </b-badge>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Responsible name</b-td>
                        <b-td>{{ activity.responsible.name }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Responsible email</b-td>
                        <b-td>{{ activity.responsible.email }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Project title</b-td>
                        <b-td>{{ activity.project.title }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Project description</b-td>
                        <b-td>{{ activity.project.description }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>

        <b-card class="mb-0">
            <b-row v-if="currentUser.roles[0].name !== 'EMPLOYEE'" align-h="end">
                <b-col cols="10">
                    <b-card-title>Tasks</b-card-title>
                </b-col>
                <b-col>
                    <b-button class="mr-1 mb-1" variant="outline-primary" @click="addTask">
                        + Task
                    </b-button>
                </b-col>
            </b-row>

            <!-- tasks -->
            <TasksTable :tasks="tasks" :next="next" @refetch="refetchData" :onRowSelected="onRowSelected" />
        </b-card>
    </div>
    <div v-else>
        <b-card>
            <b-card-title>No data to be shown</b-card-title>
        </b-card>
    </div>
</template>

<script>
import {
    BCardTitle,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BImg,
    BIconEye,
    BTbody,
    BTh,
    BTd,
    BTr,
    BThead,
    BTableSimple,
    BBadge,
    BDropdown,
    BDropdownItem,
    VBTooltip
} from 'bootstrap-vue'
import TasksTable from "@/components/molecules/tasks/TasksTable.vue"

export default {
    name: 'ProjectsDetail',
    components: {
        BCardTitle,
        BTable,
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BImg,
        BIconEye,
        BTbody,
        BTh,
        BTd,
        BTr,
        BThead,
        BTableSimple,
        BBadge,
        BDropdown,
        BDropdownItem,
        VBTooltip,
        TasksTable
    },

    directives: {
        'b-tooltip': VBTooltip
    },

    data() {
        return {
            id: null,
            next: null
        }
    },

    computed: {
        activity() {
            return this.$store.state.activityConfig.activity;
        },
        currentUser() {
            return this.$store.state.usersConfig.currentUser;
        },
        tasks() {
            if (this.currentUser.roles[0].name === 'EMPLOYEE') {
                const employeeTasks = this.activity.tasks.filter(task => task.owners.length > 0 && task.owners.some(owner => owner.id === this.currentUser.id));
                const employeeTasksActivities = this.activity.tasksActivities.filter(task => task.owners.length > 0 && task.owners.some(owner => owner.id === this.currentUser.id));
                return [...employeeTasks, ...employeeTasksActivities]
            }
            return [...this.activity.tasksActivities, ...this.activity.tasks];
        }
    },

    async created() {
        this.id = this.$route.params.id

        await Promise.all([
            this.$store.dispatch('getAllUsers'),
            this.$store.dispatch('getActivity', this.id),
        ])
        this.next = encodeURIComponent(`/activities/${this.id}`)

        this.activity.tasks.forEach(task => {
            task.owners.forEach(owner => {
                if (owner.status_timer) {
                    this.$set(owner, 'runningTimer', true)

                    let dataAlvo = new Date(owner.date_timer)
                    dataAlvo = dataAlvo.setHours(dataAlvo.getHours() - 3)
                    owner.timer = Number(owner.timer) + ((new Date() - dataAlvo) / 1000)

                    if (!owner.worker) {
                        const worker = new Worker('../timer-worker.js');

                        worker.addEventListener('message', (event) => {
                            if (event.data === 'tick') {
                                this.$set(owner, 'timer', Number(owner.timer) + 1)
                            }
                        })

                        owner.worker = worker;
                    }

                    owner.worker.postMessage('start');
                }
            })
        })

        this.activity.tasksActivities.forEach(task => {
            task.owners.forEach(owner => {
                if (owner.status_timer) {
                    this.$set(owner, 'runningTimer', true)

                    let dataAlvo = new Date(owner.date_timer)
                    dataAlvo = dataAlvo.setHours(dataAlvo.getHours() - 3)
                    owner.timer = Number(owner.timer) + ((new Date() - dataAlvo) / 1000)

                    if (!owner.worker) {
                        const worker = new Worker('../timer-worker.js');

                        worker.addEventListener('message', (event) => {
                            if (event.data === 'tick') {
                                this.$set(owner, 'timer', Number(owner.timer) + 1)
                            }
                        })

                        owner.worker = worker;
                    }

                    owner.worker.postMessage('start');
                }
            })
        })
    },

    methods: {
        async refetchData() {
            await this.$store.dispatch('getActivity', this.id);
        },

        onRowSelected(data) {
            if (data.length) {
                const taskType = data[0].task_template ? 'tasksActivities' : 'tasks';
                this.$router.push(`/tasks/${data[0].id}/activity?t=${taskType}`);
            }
        },

        activityCategoryStatus(categ) {
            const statusMap = {
                activated: 'primary',
                completed: 'success',
                cancelled: 'danger',
                pending: 'warning'
            }

            return statusMap[categ] || 'primary'
        },

        taskCategoryStatus(categ) {
            const statusMap = {
                to_do: 'primary',
                on_going: 'info',
                pending: 'warning',
                to_review: 'warning',
                to_correct: 'info',
                waiting_for_aproval: 'warning',
                approved: 'success',
                rejected: 'danger',
                cancelled: 'danger',
                to_upload: 'info'
            }

            return statusMap[categ] || '-'
        },

        taskResolveStatusText(categ) {
            const statusMap = {
                to_do: 'TO DO',
                on_going: 'ON GOING',
                pending: 'PENDING',
                to_review: 'TO REVIEW',
                to_correct: 'TO CORRECT',
                waiting_for_aproval: 'WAITING FOR APROVAL',
                approved: 'APPROVED',
                rejected: 'REJECTED',
                cancelled: 'CANCELED',
                to_upload: 'TO UPLOAD'
            }

            return statusMap[categ] || '-'
        },

        addTask() {
            this.$router.push(`/tasks/create?type=task&activity=${this.activity.id}&next=${this.next}`)
        },
    },
}
</script>